// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-bp-2019-10-26-00-js": () => import("../src/pages/blog/BP_2019_10_26_00.js" /* webpackChunkName: "component---src-pages-blog-bp-2019-10-26-00-js" */),
  "component---src-pages-blog-bp-2019-11-01-00-js": () => import("../src/pages/blog/BP_2019_11_01_00.js" /* webpackChunkName: "component---src-pages-blog-bp-2019-11-01-00-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

